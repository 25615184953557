<template>
  <CRow>
    <CCol col="12" xl="12">
      <CCard>
        <CCardHeader>
          <h1 style="float: left">Usuarios</h1>

          <CButton
            color="primary"
            class="px-4"
            style="float: right"
            @click="crear()"
            ><CIcon name="cil-user" /> Nuevo</CButton
          >
          <filters
            :campos="filterFields"
            :itemsKeys="{
              text: 'name',
              value: 'id',
            }"
            :eventName="'fetchItemsEvent'"
          ></filters>
        </CCardHeader>
        <CCardBody>
          <div class="text-center" v-if="loading">
            <div class="spinner-border" role="status"></div>
          </div>
          <CDataTable
            v-if="!loading"
            hover
            striped
            :items="items"
            :fields="fields"
            :items-per-page="50"
            :active-page="activePage"
            :pagination="{ doubleArrows: false, align: 'center' }"
            @page-change="pageChange"
          >
            <template #activo="data">
              <td>
                <CBadge :color="data.item.activo ? 'success' : 'secondary'">
                  {{ data.item.activo == 1 ? "Habilitado" : "Inhabilitado" }}
                </CBadge>
              </td>
            </template>
            <template #acciones="data">
              <td>
                <CLink
                  href="#"
                  @click="editar(data.item)"
                  class="card-header-action btn-setting"
                  v-c-tooltip.hover.click="'Editar'"
                >
                  <CIcon name="cil-pencil" />
                </CLink>
                <CLink
                  href="#"
                  @click="detalles(data.item)"
                  class="card-header-action btn-setting"
                  v-c-tooltip.hover.click="'Detalles'"
                >
                  <CIcon name="cil-list" />
                </CLink>
              </td>
            </template>
          </CDataTable>
        </CCardBody>
      </CCard>
    </CCol>
  </CRow>
</template>

<script>
import Filters from "@/components/filters";
import UserService from "@/services/user.service";
import RoleService from "@/services/role.service";
import { mapState } from "vuex";
import EventBus from "@/common/EventBus";

export default {
  name: "Users",
  data() {
    return {
      items: [],
      fields: [
        { key: "name", label: "Nombre", _classes: "font-weight-bold" },
        { key: "email", label: "Email" },
        { key: "activo", label: "Estado" },
        { key: "acciones" },
      ],
      filterFields: [
        { value: "name", label: "Nombre" },
        { value: "email", label: "Email" },
        {
          value: "activo",
          label: "Estado",
          options: [
            { id: 1, name: "Habilitado" },
            { id: 0, name: "Inhabilitado" },
          ],
        },
        // TODO: Se preveía incluir rol pero se posterga en futura fase.
        /* {
          value: "rol",
          label: "Rol",
          options: [
            { id: 1, name: "Administrador" },
            { id: 2, name: "Otro" },
          ],
        }, */
      ],
      activePage: 1,
      loading: false,
      message: "",
    };
  },
  components: {
    Filters,
  },
  watch: {
    $route: {
      immediate: true,
      handler(route) {
        if (route.query && route.query.page) {
          this.activePage = Number(route.query.page);
        }
      },
    },
  },
  computed: {
    ...mapState(["permissions"]),
  },
  mounted() {
    if (!this.permissions.includes("Gestión de Usuarios"))
      this.$router.push("/dashboard");
    this.fetchItems("");
    EventBus.on("fetchItemsEvent", (cadenaBusqueda) => {
      this.fetchItems(cadenaBusqueda);
    });
    this.fetchRoles("");
  },
  methods: {
    fetchItems(cadenaBusqueda) {
      this.loading = true;
      UserService.getItems(cadenaBusqueda).then(
        (response) => {
          this.items = response.data;
          console.log(response.data);
          this.loading = false;
        },
        (error) => {
          this.content =
            (error.response &&
              error.response.data &&
              error.response.data.message) ||
            error.message ||
            error.toString();
          this.loading = false;
        }
      );
    },
    fetchRoles() {
      // TODO buscar roles en DB
      /*this.loading = true;
      RoleService.getItems().then(
        (response) => {
          this.filterFields[2].options = response.data;
          this.loading = false;
        },
        (error) => {
          this.content =
            (error.response &&
              error.response.data &&
              error.response.data.message) ||
            error.message ||
            error.toString();
          this.loading = false;
        }
      );*/
    },
    detalles(item) {
      localStorage.setItem("datos_usuario", JSON.stringify(item));
      this.$router.push({ path: `users/user/` });
    },
    crear() {
      localStorage.removeItem("datos_usuario");
      this.$router.push({ path: `users/editar/` });
    },
    editar(item) {
      localStorage.setItem("datos_usuario", JSON.stringify(item));
      this.$router.push({ path: `users/editar/` });
    },
    pageChange(val) {
      this.$router.push({ query: { page: val } });
    },
  },
};
</script>
